import { useRouter } from 'next/router';
import {
  useCheckoutQuery,
  useCreateCheckoutPaymentMethodMutation,
  useUpdateCheckoutShippingOptionMutation,
  useUpdateCheckoutPaymentMethodMutation,
  useCompleteCheckoutMutation,
  PaymentMethodType,
  useUpdateCheckoutShippingAddressMutation,
  useUpdateCheckoutBillingAddressMutation,
  useAddCheckoutDiscountMutation,
} from '@holo/gql';
import { useCartId } from './useCartId';

export const useCheckout = () => {
  const router = useRouter();
  const cartId = useCartId();

  const id = router.query.cid as string;

  const { data: checkoutData } = useCheckoutQuery({
    skip: !id,
    variables: {
      id,
    },
  });

  const [createCheckoutPaymentMethodMutation] = useCreateCheckoutPaymentMethodMutation();
  const [updateCheckoutShippingOptionMutation] = useUpdateCheckoutShippingOptionMutation();
  const [updateCheckoutPaymentMethodMutation] = useUpdateCheckoutPaymentMethodMutation();
  const [updateCheckoutShippingAddressMutation] = useUpdateCheckoutShippingAddressMutation();
  const [updateCheckoutBillingAddressMutation] = useUpdateCheckoutBillingAddressMutation();
  const [completeCheckoutMutation] = useCompleteCheckoutMutation();
  const [addCheckoutDiscountMutation] = useAddCheckoutDiscountMutation();

  const updatePaymentMethod = async (input: any) => {
    await updateCheckoutPaymentMethodMutation({
      variables: {
        input: {
          checkoutId: checkoutData?.checkout.id,
          ...input,
        },
      },
    });
  };

  const updateShippingAddress = async (input: any) => {
    await updateCheckoutShippingAddressMutation({
      variables: {
        input: {
          checkoutId: checkoutData?.checkout.id,
          ...input,
        },
      },
    });
  };

  const updateBillingAddress = async (input: any) => {
    await updateCheckoutBillingAddressMutation({
      variables: {
        input: {
          checkoutId: checkoutData?.checkout.id,
          ...input,
        },
      },
    });
  };

  const addCheckoutDiscount = async (input: any) => {
    await addCheckoutDiscountMutation({
      variables: {
        input: {
          producerId: checkoutData?.checkout.producer.id,
          ...input,
        },
      },
    });
  };

  const updateShippingOption = async (input: any) => {
    await updateCheckoutShippingOptionMutation({
      variables: {
        input: {
          checkoutId: checkoutData?.checkout.id,
          ...input,
        },
      },
    });
  };

  const createPaymentMethod = async (options: any) => {
    const { onCompleted, refetchQueries, ...input } = options;

    await createCheckoutPaymentMethodMutation({
      onCompleted,
      awaitRefetchQueries: true,
      refetchQueries,
      variables: {
        input: {
          checkoutId: checkoutData?.checkout.id,
          paymentMethodType: PaymentMethodType.StripeCard,
          ...input,
        },
      },
    });
  };

  const completeCheckout = async (options: any) => {
    const { onCompleted, phone, note } = options;

    await completeCheckoutMutation({
      variables: {
        input: {
          checkoutId: checkoutData?.checkout.id as string,
          phone,
          note,
          cartId,
        },
      },
      onCompleted,
      awaitRefetchQueries: true,
      refetchQueries: () => ['Cart', 'Carts'],
    });
  };

  return {
    id,
    data: checkoutData,
    updatePaymentMethod,
    updateBillingAddress,
    createPaymentMethod,
    updateShippingOption,
    updateShippingAddress,
    completeCheckout,
    addCheckoutDiscount,
  };
};
