import { YStack } from '@holo/ui';
import type { CartItem as CartItemProps } from '@holo/gql';
import CartItem from './CartItem';

type Props = {
  items: Omit<CartItemProps, 'createdAt' | 'updatedAt'>[];
};

const CartItemList = ({ items }: Props) => {
  return (
    <YStack paddingHorizontal="$5">
      {items.map((item) => (
        <CartItem key={item?.id} {...item} />
      ))}
    </YStack>
  );
};

export default CartItemList;
