import { Text, Button, Icon } from '@holo/ui';
import { useTranslation } from '@holo/i18n';
import { useDrawer, useCart } from '@/hooks';

const CartButton = () => {
  const { t } = useTranslation();
  const { open } = useDrawer('cart');
  const { cart } = useCart();

  return (
    <Button
      variant="quartenary"
      onPress={open}
      disabled={!cart?.totalUniqueItems}
      cursor={cart?.totalUniqueItems ? 'pointer' : 'none'}
      opacity={1}
      testID="cart-button"
    >
      <Text color="$contrast" fontWeight="$semi-bold" display="flex" space="$2" alignItems="center">
        <Icon icon="shoppingCart" size={14} />
        {t('cart.title', { count: cart?.totalUniqueItems || 0 })}
      </Text>
    </Button>
  );
};

export default CartButton;
