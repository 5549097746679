import {
  useRemoveCartItemMutation,
  useUpdateCartItemMutation,
  useAddCartItemMutation,
  useCartQuery,
  useCheckoutCreateMutation,
  type AddToCartInput,
  type AddCartItemMutationOptions,
  type UpdateCartItemInput,
  type UpdateCartItemMutationOptions,
  type RemoveCartItemMutationOptions,
} from '@holo/gql';
import { useCartId } from './useCartId';
import { useStoreId } from './useStoreId';

type AddItemInput = Omit<AddToCartInput, 'cartId' | 'storeId'>;
type AddItemOptions = Omit<AddCartItemMutationOptions, 'variables'>;

type UpdateItemInput = Omit<UpdateCartItemInput, 'cartId' | 'storeId'>;
type UpdateItemOptions = Omit<UpdateCartItemMutationOptions, 'variables'>;

type UseCartOptions = {
  storeId?: string;
  skipCartQuery?: boolean;
};

export const useCart = (options?: UseCartOptions) => {
  const cartId = useCartId();
  const storeIdFromUrl = useStoreId();
  const storeId = options?.storeId ?? storeIdFromUrl;

  const { data, loading = true } = useCartQuery({
    skip: options?.skipCartQuery || !storeId,
    variables: {
      id: cartId,
      storeId,
    },
  });

  const [createCheckoutMutation] = useCheckoutCreateMutation();
  const [removeItemMutation] = useRemoveCartItemMutation();
  const [addItemMutation] = useAddCartItemMutation();
  const [updateItemMutation] = useUpdateCartItemMutation({
    context: {
      debounceKey: 'update-item',
    },
  });

  const addItem = async (input: AddItemInput, addItemMutationOptions: AddItemOptions = {}) => {
    const item = {
      ...input,
      unitTotal: input.price,
      lineTotal: input.price,
      notes: null,
      quantity: 1,
    };
    const items = data?.cart?.items ? [...data.cart.items, item] : [item];
    const grandTotal = data?.cart?.grandTotal ?? 0 + input.price;
    const minimumOrderAmount = data?.cart?.minimumOrderAmount ?? 0;

    await addItemMutation({
      optimisticResponse: {
        addItem: {
          id: `${cartId}:${storeId}`,
          createdAt: -1,
          updatedAt: -1,
          grandTotal,
          items,
          minimumOrderAmount,
          notes: null,
          subTotal: grandTotal,
          totalUniqueItems: items.length,
          __typename: 'Cart',
        },
      },
      variables: {
        input: {
          cartId,
          storeId,
          ...input,
        },
      },

      ...addItemMutationOptions,
    });
  };

  const removeItem = (id: string, removeItemMutationOptions: RemoveCartItemMutationOptions = {}) => {
    removeItemMutation({
      variables: {
        input: {
          cartId,
          storeId,
          id,
        },
      },
      ...removeItemMutationOptions,
    });
  };

  const updateItem = (input: UpdateItemInput, updateItemMutationOptions: UpdateItemOptions = {}) => {
    updateItemMutation({
      variables: {
        input: {
          cartId,
          storeId,
          ...input,
        },
      },
      ...updateItemMutationOptions,
    });
  };

  const createCheckout = async () => {
    return createCheckoutMutation({
      variables: {
        input: {
          checkoutId: cartId,
          storeId,
        },
      },
    });
  };

  return {
    cart: data?.cart,
    loading,
    addItem,
    removeItem,
    updateItem,
    createCheckout,
  };
};
