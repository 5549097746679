import { useState, useEffect } from 'react';
import { getUserFromToken, getAccessToken } from '@holo/jwt';
import type { User } from '@holo/jwt';

export const useSession = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const token = getAccessToken();
  const result = getUserFromToken(token as string);

  useEffect(() => {
    setUser(result);
    setLoading(false);
  }, []);

  return {
    loading,
    user,
  };
};
