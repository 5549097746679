import { useState, useEffect } from 'react';
import type { Stripe } from '@stripe/stripe-js';

// NOTE: When calling this hook we dynamicly loading the sdk
export const useStripeSDK = (load: boolean) => {
  const [stripe, setStripe] = useState<Promise<Stripe | null>>();

  const loadSDK = async () => {
    const { loadStripe } = await import('@stripe/stripe-js/pure');
    const sdk = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY as string);

    setStripe(sdk);
  };

  useEffect(() => {
    if (load && !stripe) {
      loadSDK();
    }
  }, [load]);

  return {
    stripe,
  };
};
