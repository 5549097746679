import { useReactiveVar } from '@holo/client';
import type { ModalData, ModalId } from '@/store';
import { modalsVar, modalDataVar } from '@/store';

export function useModal<Id extends ModalId>(id: Id) {
  const modals = useReactiveVar(modalsVar);

  const open = (data: ModalData<Id>) => {
    modalDataVar(data);

    modalsVar({
      currentId: id,
      ids: [...modals.ids, id],
    });
  };

  const close = () => {
    modalsVar({
      currentId: undefined,
      ids: modals.ids.filter((d) => d !== id),
    });
  };

  const data = useReactiveVar<ModalData<Id>>(modalDataVar);

  return {
    id,
    isOpen: modals.ids.includes(id),
    open,
    close,
    data,
  };
}
