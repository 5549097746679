import { useReactiveVar } from '@holo/client';
import { useCallback } from 'react';
import { searchFilterValueVar } from '@/store';
import type { ProductsByCategory } from '@/utils/getProductsByCategory';
import type { ProductEdge } from '@holo/gql';

const sortByName = (a: ProductEdge, b: ProductEdge) => {
  if (a.node && b.node) {
    return a.node.name.localeCompare(b.node?.name);
  }

  return 0;
};

const search = (products: ProductsByCategory[], searchTerm: string) => {
  return products
    .map((category) => {
      return {
        ...category,
        subCategories: category.subCategories
          .map((subCategory) => {
            return {
              ...subCategory,
              products: subCategory.products.sort(sortByName).filter((product) => {
                return product?.node?.name.toLowerCase().includes(searchTerm.toLowerCase());
              }),
            };
          })
          .filter((subCategory) => subCategory.products.length > 0),
      };
    })
    .filter((category) => category.subCategories.length > 0);
};

type BaseReturnType = {
  value: string;
  set: (filterValue: string) => string;
  clear: () => string;
};

type WithoutArgs = BaseReturnType;
type WithArgs = BaseReturnType & {
  filteredProducts: ProductsByCategory[];
};

export function useFilteredProducts(): WithoutArgs;
export function useFilteredProducts(products: ProductsByCategory[]): WithArgs;
export function useFilteredProducts(products?: ProductsByCategory[]) {
  const value = useReactiveVar(searchFilterValueVar);
  const set = useCallback((filterValue: string) => searchFilterValueVar(filterValue), []);
  const clear = useCallback(() => searchFilterValueVar(''), []);

  if (!products) {
    return {
      value,
      set,
      clear,
    };
  }

  const filteredProducts = search(products, value);

  return {
    value,
    set,
    clear,
    filteredProducts,
  };
}
