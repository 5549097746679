import type { ImageLoaderProps } from 'next/image';

const CLOUDINARY_ACCOUNT = process.env.NEXT_PUBLIC_CLOUDINARY_ACCOUNT;

type CustomImageLoaderProps = {
  square?: boolean;
} & ImageLoaderProps;

export const cloudinaryLoader = ({ src, width, square, quality = 70 }: CustomImageLoaderProps): string => {
  let params;

  if (square) {
    params = `w_${width},h_${width},c_fill`;
  }

  if (width && !square) {
    params = `w_${width}`;
  }

  // NOTE: f_auto does not work in iOS (avif), hence f_webp atm
  return `https://${CLOUDINARY_ACCOUNT}.mo.cloudinary.net/${src}?tx=${params},q_${quality},f_webp`;
};
