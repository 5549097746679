import React, { forwardRef } from 'react';
import type { TamaguiElement } from 'tamagui';
import type { BaseInputProps } from './BaseInput';
import type { FormInputProps } from './FormInput';
import BaseInput from './BaseInput';
import FormInput from './FormInput';

type Never<T> = { [I in keyof T]: never };
export type InputProps = (FormInputProps & BaseInputProps) | (Never<Partial<FormInputProps>> & BaseInputProps);

const Input = forwardRef<TamaguiElement, InputProps>(({ control, name, ...restProps }, ref) => {
  if (control && name) {
    return <FormInput ref={ref} control={control} name={name} {...restProps} />;
  }

  return <BaseInput ref={ref} {...restProps} />;
});

export default Input;
